@import url("./header.css");
@import url("./nav.css");
@import url("./sidebar.css");
@import url("./main.css");
@import url("./footer.css");

/* @import url("https://cdn.kernvalley.us/css/core-css/layout/index.css"); */

body.grid {
	grid-template-areas: "nav nav nav" "main main main" "footer footer footer";
	grid-template-rows: var(--nav-height, 4rem) auto auto;
	grid-template-columns: 8px 1fr 8px;
}

#nav {
	grid-area: nav;
}

#main {
	grid-area: main;
}

#sidebar {
	grid-area: sidebar;
}

#footer {
	grid-area: footer;
}

.content {
	margin: 1.2em;
	padding: 0.4em 1.4em;
}

article header {
	padding: 0.8rem;
}

.overflow-x-auto {
	overflow-x: auto;
}

fieldset {
	padding: 2.3em;
}

legend {
	font-size: 1.4em;
	font-weight: bold;
}

dialog {
	max-height: var(--dialog-max-height, 90vh);
	max-width: var(--dialog-max-width, 90vw);
	overflow: auto;
}

.no-margins {
	margin: 0;
}

.icon-3vw {
	height: 3vw;
	width: 3vw;
}

button.btn[is="share-button"] {
	bottom: 1rem;
	right: 1rem;
	border-radius: 50%;
	padding: 0.8rem;
	font-size: 1.5rem;
}

@media (max-width: 800px) {
	.mobile-hidden {
		display: none;
	}
}

@media (min-width: 800px) {
	body.grid {
		grid-column-gap: 1.5rem;
	}
}
