@import url("https://cdn.kernvalley.us/css/core-css/theme/default/index.css");
@import url("https://cdn.kernvalley.us/css/core-css/theme/properties.css");

@media all {
	:root:not([data-theme]), :root[data-theme] {
		--accent-color-light: #008000;
		--accent-color-dark: var(--accent-color-light);
		--button-primary-background-light: var(--accent-color-light);
		--button-primary-background-dark: var(--button-primary-background-light);
		--button-primary-hover-background-light: var(--button-primary-background-light);
		--button-primary-hover-background-dark: var(--button-primary-hover-background-light);
		--button-primary-active-background-light: #0B550B;
		--button-primary-active-background-dark: var(--button-primary-active-background-light);
		--scrollbar-track: var(--primary-color);
		--scrollbar-color: var(--accent-color);

		/* Set various backgrounds */
		--lake-background-f: url("https://i.imgur.com/vya2c7of.jpg");
		--lake-background-h: url("https://i.imgur.com/vya2c7oh.jpg");
		--lake-background-l: url("https://i.imgur.com/vya2c7ol.jpg");
		--lake-background-m: url("https://i.imgur.com/vya2c7om.jpg");
		--lake-background-s: url("https://i.imgur.com/vya2c7ot.jpg");

		--river-background-f: url("https://i.imgur.com/lQOh8Ej.jpg");
		--river-background-h: url("https://i.imgur.com/lQOh8Ejh.jpg");
		--river-background-l: url("https://i.imgur.com/lQOh8Ejl.jpg");
		--river-background-m: url("https://i.imgur.com/lQOh8Ejm.jpg");
		--river-background-s: url("https://i.imgur.com/lQOh8Ejt.jpg");

		--rafting-background-f: url("https://i.imgur.com/38gpbIs.jpg");
		--rafting-background-h: url("https://i.imgur.com/38gpbIsh.jpg");
		--rafting-background-l: url("https://i.imgur.com/38gpbIsl.jpg");
		--rafting-background-m: url("https://i.imgur.com/38gpbIsm.jpg");
		--rafting-background-s: url("https://i.imgur.com/38gpbIst.jpg");

		--hiking-background-f: url("https://i.imgur.com/T83U3Ar.jpg");
		--hiking-background-h: url("https://i.imgur.com/T83U3Arh.jpg");
		--hiking-background-l: url("https://i.imgur.com/T83U3Arl.jpg");
		--hiking-background-m: url("https://i.imgur.com/T83U3Arm.jpg");
		--hiking-background-s: url("https://i.imgur.com/T83U3Art.jpg");

		--camping-background-f: url("https://i.imgur.com/1fcVEho.jpg");
		--camping-background-h: url("https://i.imgur.com/1fcVEhoh.jpg");
		--camping-background-l: url("https://i.imgur.com/1fcVEhol.jpg");
		--camping-background-m: url("https://i.imgur.com/1fcVEhom.jpg");
		--camping-background-s: url("https://i.imgur.com/1fcVEhot.jpg");

		--fishing-background-f: url("https://i.imgur.com/owAJvQU.jpg");
		--fishing-background-h: url("https://i.imgur.com/owAJvQUh.jpg");
		--fishing-background-l: url("https://i.imgur.com/owAJvQUl.jpg");
		--fishing-background-m: url("https://i.imgur.com/owAJvQUm.jpg");
		--fishing-background-s: url("https://i.imgur.com/owAJvQUt.jpg");

		--events-background-f: url("https://i.imgur.com/NFKA5FX.jpg");
		--events-background-h: url("https://i.imgur.com/NFKA5FXh.jpg");
		--events-background-l: url("https://i.imgur.com/NFKA5FXl.jpg");
		--events-background-m: url("https://i.imgur.com/NFKA5FXm.jpg");
		--events-background-s: url("https://i.imgur.com/NFKA5FXt.jpg");
	}
}
