@import url("./scenes.css");

.home-section {
	padding: 2rem 3rem;
	min-height: 100vh;
}

@media (max-width: 600px) {
	.home-section {
		padding: 2rem 16px;
	}
}

p {
	text-indent: var(--text-indent, 0);
}

.card.card-transparant {
	background-color: rgba(255,255,255,0.6);
}

.card.card-transparant a {
	color: inherit;
}

.card. a {
	color: inherit;
}

:root[data-theme="dark"] .card.card-transparant {
	background-color: rgba(0,0,0,0.6);
	color: #fafafa;
}

@media (prefers-color-scheme: dark) {
	:root:not([data-theme="light"]) .card.card-transparant {
		background-color: rgba(0,0,0,0.6);
		color: #fafafa;
	}
}

.background-position-bottom {
	background-position: bottom;
}

.directory-listings {
	grid-template-columns: repeat(auto-fill, minmax(400px, 650px));
	grid-gap: 0.8em;
}

.directory-listings .card {
	line-height: 1.8;
}

.directory-listings figure {
	max-width: 40%;
	margin-left: 0;
}

.half-width {
	float: left;
	width: 45%;
}

@media (max-width: 600px) {
	.half-width {
		float: none;
		width: initial;
	}
}
