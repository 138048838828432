#main {
	--link-color: #049ccf;
	--link-decoration: underline;
	grid-area: main;
}

#main .floating {
	margin: 10px;
}

article p:first-letter {
	margin-left: 2em;
}
