@charset "UTF-8";
@import url("./vars.css");
@import url("https://cdn.kernvalley.us/css/normalize/normalize.css");
@import url("https://cdn.kernvalley.us/css/core-css/rem.css");
@import url("https://cdn.kernvalley.us/css/core-css/viewport.css");
@import url("https://cdn.kernvalley.us/css/core-css/element.css");
@import url("https://cdn.kernvalley.us/css/core-css/class-rules.css");
@import url("https://cdn.kernvalley.us/css/core-css/cdn-fonts.css");
@import url("https://cdn.kernvalley.us/css/core-css/animations.css");
@import url("https://cdn.kernvalley.us/css/core-css/scrollbar.css");
@import url("./layout.css");
@import url("./home.css");
@import url("./contact.css");

.mobile-block {
	display: block;
	clear: both;
}

@media (max-width: 799px) {
	.mobile-block.floating {
		float: none;
	}
}

@media (min-width: 800px) {
	.mobile-block {
		display: inline-block;
		clear: none;
	}
}

img.avatar {
	vertical-align: middle;
	margin-left: 1.2em;
}
